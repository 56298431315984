var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import Player from 'xgplayer';
import { EVENTS, Context } from 'xgplayer-helper-utils';
import FLV from './flv-live-mobile';
import defaultConfig from './config';
// import 'xgplayer-mobilevideo'
var flvAllowedEvents = EVENTS.FlvAllowedEvents;

var FlvPlayer = function (_Player) {
  _inherits(FlvPlayer, _Player);

  _createClass(FlvPlayer, null, [{
    key: 'isSupported',
    value: function isSupported() {
      var wasmSupported = 'WebAssembly' in window;
      var WebComponentSupported = 'customElements' in window && window.customElements.define;
      var isComponentDefined = false;
      if (WebComponentSupported) {
        isComponentDefined = window.customElements.get('live-video');
      }
      return wasmSupported && isComponentDefined;
    }
  }]);

  function FlvPlayer(config) {
    _classCallCheck(this, FlvPlayer);

    if (!config.mediaType) {
      config.mediaType = 'live-video';
      if (config.videoConfig) {
        config.videoConfig.preloadtime = config.preloadTime || 5;
      } else {
        config.videoConfig = {
          preloadtime: config.preloadTime || 5
        };
      }
    }

    var _this = _possibleConstructorReturn(this, (FlvPlayer.__proto__ || Object.getPrototypeOf(FlvPlayer)).call(this, config));

    _this.config = Object.assign({}, defaultConfig, _this.config);
    if (!_this.playerInited) {
      _this.initPlayer(config);
    }
    return _this;
  }

  _createClass(FlvPlayer, [{
    key: 'initPlayer',
    value: function initPlayer() {
      this.video.width = Number.parseInt(this.config.width || 600);
      this.video.height = Number.parseInt(this.config.height || 337.5);
      this.video.style.outline = 'none';
      this.context = new Context(flvAllowedEvents);
      this.playerInited = true;
    }
  }, {
    key: 'start',
    value: function start() {
      if (!this.playerInited) {
        this.initPlayer();
      }
      this.initFlv();
      this.context.init();
      this.flv.seek(0);
      _get(FlvPlayer.prototype.__proto__ || Object.getPrototypeOf(FlvPlayer.prototype), 'start', this).call(this, this.config.url);
      // this.play();
    }
  }, {
    key: 'initFlvEvents',
    value: function initFlvEvents(flv) {
      var player = this;

      flv.once(EVENTS.LOADER_EVENTS.LOADER_COMPLETE, function () {
        // 直播完成，待播放器播完缓存后发送关闭事件
        if (!player.paused) {
          var timer = setInterval(function () {
            var end = player.getBufferedRange()[1];
            if (Math.abs(player.currentTime - end) < 0.5) {
              // player.emit('ended')
              if (player.video) {
                player.video.handleEnded();
              }
              window.clearInterval(timer);
            }
          }, 200);
        }
      });
    }
  }, {
    key: 'initFlv',
    value: function initFlv() {
      var flv = this.context.registry('FLV_CONTROLLER', FLV)(this);
      this.initFlvEvents(flv);
      this.flv = flv;
    }
  }, {
    key: 'play',
    value: function play() {
      if (this._hasStart && this.paused) {
        this._destroy();
        this.context = new Context(flvAllowedEvents);
        var flv = this.context.registry('FLV_CONTROLLER', FLV)(this);
        this.initFlvEvents(flv);
        this.flv = flv;
        this.context.init();
        this.loadData();
        _get(FlvPlayer.prototype.__proto__ || Object.getPrototypeOf(FlvPlayer.prototype), 'start', this).call(this);
        _get(FlvPlayer.prototype.__proto__ || Object.getPrototypeOf(FlvPlayer.prototype), 'play', this).call(this);
      } else {
        _get(FlvPlayer.prototype.__proto__ || Object.getPrototypeOf(FlvPlayer.prototype), 'play', this).call(this);
        this.addLiveFlag();
      }
    }
  }, {
    key: 'pause',
    value: function pause() {
      _get(FlvPlayer.prototype.__proto__ || Object.getPrototypeOf(FlvPlayer.prototype), 'pause', this).call(this);
      if (this.flv) {
        this.flv.pause();
      }
    }
  }, {
    key: 'loadData',
    value: function loadData() {
      var time = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.currentTime;

      if (this.flv) {
        this.flv.seek(time);
      }
    }
  }, {
    key: 'destroy',
    value: function destroy() {
      var isDelDOM = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      this._destroy();
      var video = this.video,
          root = this.root;

      _get(FlvPlayer.prototype.__proto__ || Object.getPrototypeOf(FlvPlayer.prototype), 'destroy', this).call(this, isDelDOM);
      if (video && video.remove) {
        video.remove();
      } else if (root) {
        root.removeChild(video);
      }
    }
  }, {
    key: 'addLiveFlag',
    value: function addLiveFlag() {
      var player = this;
      Player.util.addClass(player.root, 'xgplayer-is-live');
      if (!Player.util.findDom(this.root, 'xg-live')) {
        var live = Player.util.createDom('xg-live', '正在直播', {}, 'xgplayer-live');
        player.controls.appendChild(live);
      }
    }
  }, {
    key: '_destroy',
    value: function _destroy() {
      this.context.destroy();
      this.flv = null;
      this.context = null;
    }
  }, {
    key: 'src',
    get: function get() {
      return this.currentSrc;
    },
    set: function set(url) {
      this.config.url = url;
      if (!this.paused) {
        this.currentTime = 0;
        this.pause();
        // this.once('pause', () => {
        //   this.start(url)
        // })
        this.play();
      } else {
        this.start(url);
      }
    }
  }]);

  return FlvPlayer;
}(Player);

export default FlvPlayer;