var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import Player from 'xgplayer';
import { EVENTS, Context } from 'xgplayer-helper-utils';
import FLV from './flv-live';
import defaultConfig from './config';
var flvAllowedEvents = EVENTS.FlvAllowedEvents;

var FlvPlayer = function (_Player) {
  _inherits(FlvPlayer, _Player);

  _createClass(FlvPlayer, null, [{
    key: 'isSupported',
    value: function isSupported() {
      return window.MediaSource && window.MediaSource.isTypeSupported('video/mp4; codecs="avc1.42E01E,mp4a.40.2"');
    }
  }, {
    key: 'install',
    value: function install(name, plugin) {
      return Player.install(name, plugin);
    }
  }]);

  function FlvPlayer(config) {
    _classCallCheck(this, FlvPlayer);

    var _this = _possibleConstructorReturn(this, (FlvPlayer.__proto__ || Object.getPrototypeOf(FlvPlayer)).call(this, config));

    _this.config = Object.assign({}, defaultConfig, _this.config);
    _this.initEvents();
    _this.loaderCompleteTimer = null;
    _this.hasPlayed = false;
    // const preloadTime = player.config.preloadTime || 15
    return _this;
  }

  _createClass(FlvPlayer, [{
    key: 'start',
    value: function start() {
      if (this.started) {
        return;
      }
      if (this.context) {
        this.context.destroy();
      }
      this.context = new Context(this, this.config, flvAllowedEvents);
      this.initFlv();
      this.context.init();
      _get(FlvPlayer.prototype.__proto__ || Object.getPrototypeOf(FlvPlayer.prototype), 'start', this).call(this, this.flv.mse.url);
      this.loadData();
      this.started = true;
    }
  }, {
    key: 'initFlvEvents',
    value: function initFlvEvents(flv) {
      var _this2 = this;

      var player = this;

      flv.once(EVENTS.REMUX_EVENTS.INIT_SEGMENT, function () {
        Player.util.addClass(player.root, 'xgplayer-is-live');
      });

      flv.on(EVENTS.LOADER_EVENTS.LOADER_COMPLETE, function () {
        // 直播完成，待播放器播完缓存后发送关闭事件
        if (!player.paused) {
          if (_this2.video) {
            var end = _this2.getBufferedRange()[1];
            var restTime = end - _this2.currentTime;
            setTimeout(function () {
              _this2.emit('ended');
            }, restTime * 1000);
          }
        } else {
          player.emit('ended');
        }
      });
    }
  }, {
    key: 'initFlvBackupEvents',
    value: function initFlvBackupEvents(flv, ctx) {
      var _this3 = this;

      var mediaLength = 3;
      flv.on(EVENTS.REMUX_EVENTS.MEDIA_SEGMENT, function () {
        mediaLength -= 1;
        if (mediaLength === 0) {
          // ensure switch smoothly
          _this3.flv = flv;
          _this3.mse.resetContext(ctx);
          _this3.context.destroy();
          _this3.context = ctx;
          if (_this3.paused) {
            _get(FlvPlayer.prototype.__proto__ || Object.getPrototypeOf(FlvPlayer.prototype), 'play', _this3).call(_this3);
          }
        }
      });

      flv.once(EVENTS.LOADER_EVENTS.LOADER_COMPLETE, function () {
        // 直播完成，待播放器播完缓存后发送关闭事件
        if (!_this3.paused) {
          if (_this3.video) {
            var end = _this3.getBufferedRange()[1];
            var restTime = end - _this3.currentTime;
            setTimeout(function () {
              _this3.emit('ended');
            }, restTime * 1000);
          }
        } else {
          _this3.emit('ended');
        }
      });

      flv.once(EVENTS.LOADER_EVENTS.LOADER_ERROR, function () {
        ctx.destroy();
      });
    }
  }, {
    key: 'initEvents',
    value: function initEvents() {
      var _this4 = this;

      this.on('seeking', function () {
        var time = _this4.currentTime;
        var range = _this4.getBufferedRange();
        if (time > range[1] || time < range[0]) {
          _this4.flv.seek(_this4.currentTime);
        }
      });

      // support for old version xgplayer
      this.once('play', function () {
        _this4.hasPlayed = true;
      });
    }
  }, {
    key: 'initFlv',
    value: function initFlv() {
      var flv = this.context.registry('FLV_CONTROLLER', FLV)(this, { retryTimes: this.config.retryTimes });
      this.initFlvEvents(flv);
      this.flv = flv;
      this.mse = flv.mse;
      return flv;
    }
  }, {
    key: 'play',
    value: function play() {
      var _this5 = this;

      if (this.paused && this.hasPlayed) {
        return this.mse.cleanBuffers().then(function () {
          _this5.started = false;
          _this5.start();
          return _get(FlvPlayer.prototype.__proto__ || Object.getPrototypeOf(FlvPlayer.prototype), 'play', _this5).call(_this5);
        });
      } else {
        this.hasPlayed = true;
        return _get(FlvPlayer.prototype.__proto__ || Object.getPrototypeOf(FlvPlayer.prototype), 'play', this).call(this);
      }
    }
  }, {
    key: 'pause',
    value: function pause() {
      _get(FlvPlayer.prototype.__proto__ || Object.getPrototypeOf(FlvPlayer.prototype), 'pause', this).call(this);
      if (this.flv) {
        this.flv.pause();
        this.hasPlayed = true;
      }
    }
  }, {
    key: 'loadData',
    value: function loadData() {
      var time = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.currentTime;

      if (this.flv) {
        this.flv.seek(time);
      }
    }
  }, {
    key: 'destroy',
    value: function destroy() {
      var _this6 = this;

      var prom = new Promise(function (resolve) {
        _this6._destroy().then(resolve).catch(resolve);
        setTimeout(function () {
          resolve();
        }, 50);
      });
      _get(FlvPlayer.prototype.__proto__ || Object.getPrototypeOf(FlvPlayer.prototype), 'destroy', this).call(this);
      return prom;
    }
  }, {
    key: '_destroy',
    value: function _destroy() {
      var _this7 = this;

      if (this.context) {
        this.context.destroy();
      }
      if (this.newContext) {
        this.newContext.destroy();
      }
      var work = function work() {
        _this7.flv = null;
        _this7.context = null;
        _this7.newFlv = null;
        _this7.newContext = null;
      };
      if (this.flv && this.flv.mse) {
        return this.flv.mse.destroy().then(function () {
          work();
        });
      }
      return Promise.resolve().then(function () {
        work();
      });
    }
  }, {
    key: 'switchURL',


    /**
     * switch to another flv live url
     * @param url
     * @param seamless
     */
    value: function switchURL(url) {
      var seamless = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (!seamless) {
        this.pause();
        this.context.destroy();
      }
      var context = new Context(this, this.config, flvAllowedEvents);
      var flv = context.registry('FLV_CONTROLLER', FLV)(this, { retryTimes: this.config.retryTimes }, this.mse);
      this.newContext = context;
      this.newFlv = flv;
      context.init();
      this.initFlvBackupEvents(flv, context);
      flv.loadData(url);
    }
  }, {
    key: 'src',
    get: function get() {
      return this.currentSrc;
    },
    set: function set(url) {
      this.switchURL(url);
    }
  }, {
    key: 'core',
    get: function get() {
      return this.flv;
    }
  }]);

  return FlvPlayer;
}(Player);

export default FlvPlayer;