var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import Demuxer from './demuxer';
import { AudioTrackMeta, VideoTrackMeta, VideoTrack, AudioTrack } from 'xgplayer-helper-models';
import { EVENTS } from 'xgplayer-helper-utils';

var DEMUX_EVENTS = EVENTS.DEMUX_EVENTS;
var INTERNAL_EVENTS = Demuxer.EVENTS;

var FlvDemuxer = function () {
  function FlvDemuxer() {
    _classCallCheck(this, FlvDemuxer);

    this.TAG = 'FLV_DEMUXER';
    this._firstFragmentLoaded = false;
    this._trackNum = 0;
    this._hasScript = false;
    this._videoMetaChange = false;
    this._audioMetaChange = false;
    this._hasVideoSequence = false;
    this._hasAudioSequence = false;
    this.demuxer = new Demuxer();
  }

  _createClass(FlvDemuxer, [{
    key: 'init',
    value: function init() {
      this.on(DEMUX_EVENTS.DEMUX_START, this.demux.bind(this));
      this.demuxer.on(INTERNAL_EVENTS.FILE_HEADER_PARSED, this.handleFileHeaderParsed.bind(this));
      this.demuxer.on(INTERNAL_EVENTS.SCRIPT_TAG_PARSED, this.handleScriptTagParsed.bind(this));
      this.demuxer.on(INTERNAL_EVENTS.AUDIO_META_PARSED, this.handleAudioMetaParsed.bind(this));
      this.demuxer.on(INTERNAL_EVENTS.VIDEO_META_PARSED, this.handleVideoMetaParsed.bind(this));
      this.demuxer.on(INTERNAL_EVENTS.VIDEO_SAMPLE_PARSED, this.handleVideoSampleParsed.bind(this));
      this.demuxer.on(INTERNAL_EVENTS.AUDIO_SAMPLE_PARSED, this.handleAudioSampleParsed.bind(this));
      this.demuxer.on(INTERNAL_EVENTS.VIDEO_SEI_PARSED, this.handleSeiParsed.bind(this));
    }
  }, {
    key: 'handleAudioMetaParsed',
    value: function handleAudioMetaParsed(meta) {
      if (!this.tracks || !this.tracks.audioTrack) {
        return;
      }
      this._context.mediaInfo.hasAudio = true;
      this.tracks.audioTrack.meta = meta;
      if (!this._hasAudioSequence) {
        this.emit(DEMUX_EVENTS.METADATA_PARSED, 'audio');
      } else {
        this.emit(DEMUX_EVENTS.AUDIO_METADATA_CHANGE);
      }
    }
  }, {
    key: 'handleVideoMetaParsed',
    value: function handleVideoMetaParsed(meta) {
      if (!this.tracks || !this.tracks.videoTrack) {
        return;
      }
      this._context.mediaInfo.hasVideo = true;
      this.tracks.videoTrack.meta = meta;
      if (!this._hasVideoSequence) {
        this.emit(DEMUX_EVENTS.METADATA_PARSED, 'video');
      } else {
        this.emit(DEMUX_EVENTS.VIDEO_METADATA_CHANGE);
      }
    }
  }, {
    key: 'handleSeiParsed',
    value: function handleSeiParsed(seiObj) {
      this.emit(DEMUX_EVENTS.SEI_PARSED, seiObj);
    }
  }, {
    key: 'handleVideoSampleParsed',
    value: function handleVideoSampleParsed(sample) {
      if (!this.tracks || !this.tracks.videoTrack) {
        return;
      }
      if (sample.isKeyframe) {
        this.emit(DEMUX_EVENTS.ISKEYFRAME, sample.dts + sample.cts);
      }
      this.tracks.videoTrack.samples.push(sample);
    }
  }, {
    key: 'handleAudioSampleParsed',
    value: function handleAudioSampleParsed(sample) {
      if (!this.tracks || !this.tracks.videoTrack) {
        return;
      }
      this.tracks.audioTrack.samples.push(sample);
    }
  }, {
    key: 'handleScriptTagParsed',
    value: function handleScriptTagParsed(onMetaData) {
      var videoTrack = this.videoTrack,
          audioTrack = this.audioTrack;
      // fill mediaInfo

      this._context.mediaInfo.duration = onMetaData.duration;
      if (typeof onMetaData.hasAudio === 'boolean') {
        this._context.mediaInfo.hsaAudio = onMetaData.hasAudio;
      }
      if (typeof onMetaData.hasVideo === 'boolean') {
        this._context.mediaInfo.hasVideo = onMetaData.hasVideo;
      }
      this.emit(DEMUX_EVENTS.MEDIA_INFO);
      this._hasScript = true;

      // Edit default meta.
      if (audioTrack && !audioTrack.hasSpecificConfig) {
        var meta = audioTrack.meta;
        if (onMetaData.audiosamplerate) {
          meta.sampleRate = onMetaData.audiosamplerate;
        }

        if (onMetaData.audiochannels) {
          meta.channelCount = onMetaData.audiochannels;
        }

        switch (onMetaData.audiosamplerate) {
          case 44100:
            meta.sampleRateIndex = 4;
            break;
          case 22050:
            meta.sampleRateIndex = 7;
            break;
          case 11025:
            meta.sampleRateIndex = 10;
            break;
        }
      }
      if (videoTrack && !videoTrack.hasSpecificConfig) {
        var _meta = videoTrack.meta;
        if (typeof onMetaData.framerate === 'number') {
          var fpsNum = Math.floor(onMetaData.framerate * 1000);
          if (fpsNum > 0) {
            var fps = fpsNum / 1000;
            if (!_meta.frameRate) {
              _meta.frameRate = {};
            }
            _meta.frameRate.fixed = true;
            _meta.frameRate.fps = fps;
            _meta.frameRate.fps_num = fpsNum;
            _meta.frameRate.fps_den = 1000;
          }
        }
      }
    }
  }, {
    key: 'handleFileHeaderParsed',
    value: function handleFileHeaderParsed(_ref) {
      var hasVideo = _ref.hasVideo,
          hasAudio = _ref.hasAudio;

      this._context.mediaInfo.hasVideo = hasVideo;
      this._context.mediaInfo.hasAudio = hasAudio;

      this.initVideoTrack();
      this.initAudioTrack();
    }
  }, {
    key: 'demux',
    value: function demux() {
      if (this.loaderBuffer) {
        try {
          this.demuxer.demux(this.loaderBuffer);
        } catch (e) {
          // this.emit(DEMUX_EVENTS.DEMUX_ERROR, this.TAG, e)
        }
        this.emit(DEMUX_EVENTS.DEMUX_COMPLETE);
      }
    }
    /**
     * If the stream has audio or video.
     * @param {number} streamFlag - Data from the stream which is define whether the audio / video track is exist.
     */

  }, {
    key: 'initVideoTrack',


    /**
     * init default video track configs
     */
    value: function initVideoTrack() {
      this._trackNum++;
      var videoTrack = new VideoTrack();
      videoTrack.meta = new VideoTrackMeta();
      videoTrack.id = videoTrack.meta.id = this._trackNum;

      this.tracks.videoTrack = videoTrack;
    }

    /**
     * init default audio track configs
     */

  }, {
    key: 'initAudioTrack',
    value: function initAudioTrack() {
      this._trackNum++;
      var audioTrack = new AudioTrack();
      audioTrack.meta = new AudioTrackMeta();
      audioTrack.id = audioTrack.meta.id = this._trackNum;

      this.tracks.audioTrack = audioTrack;
    }
  }, {
    key: 'destroy',
    value: function destroy() {
      if (this.demuxer) {
        this.demuxer.destroy();
      }
    }
  }, {
    key: 'loaderBuffer',
    get: function get() {
      var buffer = this._context.getInstance('LOADER_BUFFER');
      if (buffer) {
        return buffer;
      } else {
        this.emit(DEMUX_EVENTS.DEMUX_ERROR, this.TAG, new Error('找不到 loaderBuffer 实例'));
      }
    }
  }, {
    key: 'tracks',
    get: function get() {
      return this._context.getInstance('TRACKS');
    }
  }, {
    key: 'logger',
    get: function get() {
      return this._context.getInstance('LOGGER');
    }
  }], [{
    key: 'getPlayType',
    value: function getPlayType(streamFlag) {
      var result = {
        hasVideo: false,
        hasAudio: false
      };

      if (streamFlag & 0x01 > 0) {
        result.hasVideo = true;
      }

      if (streamFlag & 0x04 > 0) {
        result.hasAudio = true;
      }

      return result;
    }
  }]);

  return FlvDemuxer;
}();

export default FlvDemuxer;