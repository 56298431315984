function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FlvTag = function FlvTag() {
  _classCallCheck(this, FlvTag);

  /** @type {number} */
  this.filtered = -1;
  /** @type {number} */
  this.tagType = -1;
  /** @type {number} */
  this.datasize = -1;
  /** @type {number} */
  this.dts = -1;
};

export default FlvTag;