var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import Player from 'xgplayer';
import FlvLiveMsePlayer from './mse';
import FlvLiveMobilePlayer from './mobile';
import { EVENTS } from 'xgplayer-helper-utils';

var FlvLivePlayer = function () {
  function FlvLivePlayer(config) {
    _classCallCheck(this, FlvLivePlayer);

    if (FlvLiveMobilePlayer.isSupported() && config.useWASM) {
      return new FlvLiveMobilePlayer(config);
    }if (FlvLiveMsePlayer.isSupported()) {
      return new FlvLiveMsePlayer(config);
    } else {
      return new Player(config);
    }
  }

  _createClass(FlvLivePlayer, null, [{
    key: 'install',
    value: function install(name, plugin) {
      return Player.install(name, plugin);
    }
  }]);

  return FlvLivePlayer;
}();

export default FlvLivePlayer;


FlvLivePlayer.EVENTS = EVENTS;