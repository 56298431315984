var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import { EVENTS } from 'xgplayer-helper-utils';
import { avc, hevc } from 'xgplayer-helper-codec';
import { XGDataView } from 'xgplayer-helper-models';
import Player from 'xgplayer';

var NalUnit = avc.NalUnit;
var NalUnitHEVC = hevc.NalUnitHEVC;

var DEMUX_EVENTS = EVENTS.DEMUX_EVENTS;
var LOADER_EVENTS = EVENTS.LOADER_EVENTS;

var Tag = 'FLVController';

var FLV_ERROR = 'FLV_ERROR';

var FlvController = function () {
  function FlvController(player) {
    _classCallCheck(this, FlvController);

    this.TAG = Tag;

    this.state = {
      initSegmentArrived: false,
      randomAccessPoints: []
    };

    this.bufferClearTimer = null;
  }

  _createClass(FlvController, [{
    key: 'init',
    value: function init() {
      this.initComponents();
      this.initListeners();
    }
  }, {
    key: 'initComponents',
    value: function initComponents() {
      var _player$config = this._player.config,
          FetchLoader = _player$config.FetchLoader,
          XgBuffer = _player$config.XgBuffer,
          FlvDemuxer = _player$config.FlvDemuxer,
          Tracks = _player$config.Tracks,
          Logger = _player$config.Logger;

      this._context.registry('FETCH_LOADER', FetchLoader);
      this._context.registry('LOADER_BUFFER', XgBuffer);

      this._context.registry('FLV_DEMUXER', FlvDemuxer);
      this._context.registry('TRACKS', Tracks);

      this._context.registry('LOGGER', Logger);
      // this._context.registry('PAGE_VISIBILITY', PageVisibility)
    }
  }, {
    key: 'initListeners',
    value: function initListeners() {
      this.on(LOADER_EVENTS.LOADER_DATALOADED, this._handleLoaderDataLoaded.bind(this));
      this.on(LOADER_EVENTS.LOADER_ERROR, this._handleNetworkError.bind(this));

      this.on(DEMUX_EVENTS.MEDIA_INFO, this._handleMediaInfo.bind(this));
      this.on(DEMUX_EVENTS.METADATA_PARSED, this._handleMetadataParsed.bind(this));
      this.on(DEMUX_EVENTS.DEMUX_COMPLETE, this._handleDemuxComplete.bind(this));
      this.on(DEMUX_EVENTS.DEMUX_ERROR, this._handleDemuxError.bind(this));
      this.on(DEMUX_EVENTS.SEI_PARSED, this._handleSEIParsed.bind(this));
      // this.on(BROWSER_EVENTS.VISIBILITY_CHANGE, this._handleVisibilityChange.bind(this))
    }
  }, {
    key: '_handleMediaInfo',
    value: function _handleMediaInfo() {
      // meta extracted from flv scriptData
      if (!this._context.mediaInfo) {
        this.emit(DEMUX_EVENTS.DEMUX_ERROR, new Error('failed to get mediainfo'));
      } else {
        if (this._player.video) {
          this._player.video.handleMediaInfo();
        }
      }
    }
  }, {
    key: '_handleLoaderDataLoaded',
    value: function _handleLoaderDataLoaded() {
      this.emitTo('FLV_DEMUXER', DEMUX_EVENTS.DEMUX_START);
    }
  }, {
    key: '_handleSEIParsed',
    value: function _handleSEIParsed(sei) {
      this._player.emit('SEI_PARSED', sei);
    }
  }, {
    key: '_handleDemuxComplete',
    value: function _handleDemuxComplete() {
      var _this = this;

      if (this._player.video) {
        var _context$getInstance = this._context.getInstance('TRACKS'),
            videoTrack = _context$getInstance.videoTrack,
            audioTrack = _context$getInstance.audioTrack;

        videoTrack.samples.forEach(function (sample) {
          if (sample.analyzed) {
            return;
          }

          sample.analyzed = true;
          var buffer = new XGDataView(sample.data.buffer);
          var nals = void 0;
          if (_this._isHEVC(videoTrack.meta)) {
            nals = NalUnitHEVC.getHvccNals(buffer);
          } else {
            nals = NalUnit.getNalunits(buffer);
          }
          var nalsLength = nals.reduce(function (len, current) {
            return len + 4 + current.body.byteLength;
          }, 0);
          var newData = new Uint8Array(nalsLength);
          var offset = 0;

          // byte-stream nalunit to AnnexB
          nals.forEach(function (nal) {
            newData.set([0, 0, 0, 1], offset);
            offset += 4;
            newData.set(new Uint8Array(nal.body), offset);
            offset += nal.body.byteLength;
          });

          sample.data = newData;
        });
        this._player.video.onDemuxComplete(videoTrack, audioTrack);
      }
    }
  }, {
    key: '_handleVisibilityChange',
    value: function _handleVisibilityChange(hidden) {
      var visible = !hidden;
      if (!visible && !this._player.paused) {
        this._player.pause();
      } else if (visible && this._player.paused) {
        this._player.play();
      }
    }
  }, {
    key: '_handleMetadataParsed',
    value: function _handleMetadataParsed(type) {
      if (type === 'audio') {
        // 将音频meta信息交给audioContext，不走remux封装
        var _context$getInstance2 = this._context.getInstance('TRACKS'),
            audioTrack = _context$getInstance2.audioTrack;

        if (audioTrack && audioTrack.meta) {
          this._setMetaToAudio(audioTrack.meta);
        }
      } else {
        var _context$getInstance3 = this._context.getInstance('TRACKS'),
            videoTrack = _context$getInstance3.videoTrack;

        if (videoTrack && videoTrack.meta) {
          this._setMetaToVideo(videoTrack.meta);
        }
      }
    }
  }, {
    key: '_setMetaToAudio',
    value: function _setMetaToAudio(audioMeta) {
      if (this._player.video) {
        this._player.video.setAudioMeta(audioMeta);
      }
    }
  }, {
    key: '_setMetaToVideo',
    value: function _setMetaToVideo(videoMeta) {
      if (this._player.video) {
        this._player.video.setVideoMeta(videoMeta);
      }
    }
  }, {
    key: '_handleAppendInitSegment',
    value: function _handleAppendInitSegment() {
      this.state.initSegmentArrived = true;
    }
  }, {
    key: '_handleNetworkError',
    value: function _handleNetworkError(tag, err) {
      this._player.emit('error', new Player.Errors('network', this._player.config.url));
      this._onError(LOADER_EVENTS.LOADER_ERROR, tag, err, true, 'MEDIA_ERR_NETWORK');
    }
  }, {
    key: '_handleDemuxError',
    value: function _handleDemuxError(tag, err, fatal) {
      if (fatal === undefined) {
        fatal = false;
      }
      this._player.emit('error', new Player.Errors('parse', this._player.config.url));
      this._onError(DEMUX_EVENTS.DEMUX_ERROR, tag, err, fatal, 'MEDIA_ERR_DECODE');
    }
  }, {
    key: '_handleAddRAP',
    value: function _handleAddRAP(rap) {
      if (this.state.randomAccessPoints) {
        this.state.randomAccessPoints.push(rap);
      }
    }
  }, {
    key: '_onError',
    value: function _onError(type, mod, err, fatal, code) {
      var detail = '[' + mod + ']: ' + err.message;
      var error = {
        errorType: type,
        errorDetails: detail,
        errorFatal: fatal || false
      };
      this._player.emit(FLV_ERROR, error);
      if (this._player.video) {
        this._player.video.handleErr(code, detail);
      }
    }
  }, {
    key: '_isHEVC',
    value: function _isHEVC(meta) {
      return meta && meta.codec === 'hev1.1.6.L93.B0';
    }
  }, {
    key: 'seek',
    value: function seek() {
      if (!this.state.initSegmentArrived) {
        this.loadData();
      }
    }
  }, {
    key: 'loadData',
    value: function loadData() {
      var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this._player.config.url;

      this.emit(LOADER_EVENTS.LADER_START, url);
    }
  }, {
    key: 'pause',
    value: function pause() {
      var loader = this._context.getInstance('FETCH_LOADER');

      if (loader) {
        loader.cancel();
      }
    }
  }, {
    key: 'destroy',
    value: function destroy() {
      this.state.randomAccessPoints = [];
    }
  }]);

  return FlvController;
}();

export default FlvController;