var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import { FlvDemuxer } from 'xgplayer-helper-transmuxers';
import { FetchLoader } from 'xgplayer-helper-utils';
import { Tracks, Buffer as XgBuffer } from 'xgplayer-helper-models';

var Logger = function () {
  function Logger() {
    _classCallCheck(this, Logger);
  }

  _createClass(Logger, [{
    key: 'warn',
    value: function warn() {}
  }]);

  return Logger;
}();

export default {
  FlvDemuxer: FlvDemuxer,
  FetchLoader: FetchLoader,
  Tracks: Tracks,
  XgBuffer: XgBuffer,
  Logger: Logger
};